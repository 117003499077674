import { Box, Container, CssBaseline, FormControl, InputLabel, Grid, Tooltip, Typography, MenuItem, Select } from '@mui/material'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { DataGrid, exportData } from '@mui/x-data-grid'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { DataTable } from '../components'
import { getUser } from '../utils/AuthSession'
import { formatDateTime, thousandSeperator } from '../utils/Format'
import { Parser } from 'json2csv';

const Audits = () => {
  const [trails, setTrails] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)

  const [dataGridInstance, setDataGridInstance] = useState(null);

  useEffect(() => {
    fetchTrail()
  }, [selectedMonth, selectedYear, refresh])


  const fetchTrail = () => {
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API_URL}/audits/auditTrail`,
      {
        user_id: getUser().id,
        selectedMonth: selectedMonth,
        selectedYear: selectedYear
      }
    )
      .then(res => {
        console.log(res.data);
        setTrails(res.data)
        setLoading(false)
      })
      .catch(err => {
        alert(err.response.data)
        setLoading(false)
      })
  }

  const deleteTrails = () => {
    setLoading(true)
    axios.post(`${process.env.REACT_APP_API_URL}/audits/auditTrail/delete`,
      {
        user_id: getUser().id,
        selectedMonth: selectedMonth,
        selectedYear: selectedYear
      }
    )
      .then(res => {
        console.log(res.data);
        alert(res.data)
        setRefresh(!refresh)
        setLoading(false)
      })
      .catch(err => {
        alert(err.response.data)
        setLoading(false)
      })
  }

  const exportMonthlyAuditTrails = () => {
    console.log(`exportting audit trails for month-${selectedMonth} and year-${selectedYear}`)
    const user = getUser()

    axios.post(`${process.env.REACT_APP_API_URL}/admins/auditTrailsInMonth`, { selectedMonth, selectedYear, user_id: user.id })
      .then(res => {
        console.log(res.data);
        //create csv file of the data
        const fileName = `Audit Trails ${['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][selectedMonth - 1]} ${selectedYear}`
        let fields = ['ID', 'Action By', 'Description', 'Key', 'Previous Value', 'New Value', 'Action Date Time', 'Action Status']
        const opts = { fields };

        const parser = new Parser(opts);
        const csv = parser.parse(res.data);

        downloadFile({
          data: csv,
          fileName: fileName,
          fileType: 'text/csv'
        })

      })
      .catch(err => {
        alert(err.response.data)
      })

  }
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })

    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
  }


  const columns = [
    {
      field: 'id', headerName: 'ID'
    },
    {
      field: 'username', headerName: 'Action By',
      renderCell: params => {
        if (params.row.user_id === 0) {
          return 'System'
        } else {
          return params.row.username
        }
      }
    },
    {
      field: 'description', headerName: 'Description', minWidth: '200',
      renderCell: params => {
        return <Tooltip title={params.row.description}>
          <p>
            {params.row.description}
          </p>
        </Tooltip>
      }
    },
    {
      field: 'key', headerName: 'Key', minWidth: '200',
      renderCell: params => {
        return <Tooltip title={params.row.key}>
          <p>
            {params.row.key}
          </p>
        </Tooltip>
      }
    },
    {
      field: 'previousValue', headerName: 'Previous Value', minWidth: '200',
      renderCell: (params) => {
        if (params.row.audit_type === 3) {
          return JSON.parse(params.row.previousValue).company_name
        }
        else if (params.row.audit_type === 7) {
          let data = JSON.parse(params.row.previousValue)
          return <Tooltip title={`${data.user_id} | ${data.username}`}>
            <p>
              {`${data.user_id} | ${data.username}`}
            </p>
          </Tooltip>
        }
        else if (params.row.audit_type === 11) {
          let data = JSON.parse(params.row.previousValue)
          return <Tooltip title={`to: ${JSON.stringify(data.to)}`}>
            <p style={{ overflowWrap: 'anywhere' }}>
              {`to: ${JSON.stringify(data.to)}`}
            </p>
          </Tooltip>
        }
        else if (params.row.audit_type === 18) {
          let data = JSON.parse(params.row.previousValue)
          let value = ''
          Object.keys(data).forEach(item => {
            value = value + (`${item}: ${data[item]}, `)
          })
          return <Tooltip title={value.substring(0, value.length - 2)}>
            <p>
              {value.substring(0, value.length - 2)}
            </p>
          </Tooltip>
        }
        else if (params.row.audit_type === 22) {
          let data = JSON.parse(params.row.previousValue)
          return <Tooltip title={data.lastBidAmount ? `Last Bid:$${thousandSeperator(data?.lastBidAmount)} | Bid Type:${data?.car_or_body} | Car ID:${data?.car_id}` : '-'}>
            <p>
              {data.lastBidAmount ? `Last Bid:$${thousandSeperator(data?.lastBidAmount)} | Bid Type:${data?.car_or_body} | Car ID:${data?.car_id}` : '-'}
            </p>
          </Tooltip>
        }
        else if (params.row.audit_type === 23) {
          let data = JSON.parse(params.row.previousValue)
          return `Bid ID:${data.bid_id}`
        }
        else if (params.row.audit_type === 24 || params.row.audit_type === 25) {
          let data = JSON.parse(params.row.previousValue)
          return <Tooltip title={`emailTo: ${data.emailTo}`}>
            <p>
              {`emailTo: ${data.emailTo}`}
            </p>
          </Tooltip>
        }
        else {
          return params.row.previousValue
        }
      }
    },
    {
      field: 'newValue', headerName: 'New Value', minWidth: '400',
      renderCell: params => {
        if (params.row.audit_type === 5) {
          let data = JSON.parse(params.row.newValue)
          return <Tooltip title={`${data.name} | ${data.username} | ${data.email}`}>
            <p>
              {`${data.name} | ${data.username} | ${data.email}`}
            </p>
          </Tooltip>
        }
        else if (params.row.audit_type === 15) {
          let data = JSON.parse(params.row.newValue)
          return <Tooltip title={`${data.session_no}`}>
            <p>
              {`Session No - ${data.session_no}`}
            </p>
          </Tooltip>
        }
        else if (params.row.audit_type === 18) {
          let data = JSON.parse(params.row.newValue)
          let value = ''
          Object.keys(data).forEach(item => {
            value = value + (`${item}: ${data[item]}, `)
          })
          return <Tooltip title={value.substring(0, value.length - 2)}>
            <p>
              {value.substring(0, value.length - 2)}
            </p>
          </Tooltip>
        }
        else if (params.row.audit_type === 20) {
          let data = JSON.parse(params.row.newValue)
          return `Winning bid id - ${data.bid_id}`
        }
        else if (params.row.audit_type === 22) {
          let data = JSON.parse(params.row.newValue)
          return <Tooltip title={`Last Bid:$${thousandSeperator(data.newBidAmount)} | Bid Type:${data.car_or_body} | Car ID:${data.car_id}`}>
            <p>
              {`New Bid:$${thousandSeperator(data.newBidAmount)} | Bid Type:${data.car_or_body} | Car ID:${data.car_id}`}
            </p>
          </Tooltip>
        }
        else if (params.row.audit_type === 11 || params.row.audit_type === 24 || params.row.audit_type === 25) {
          return <Tooltip title={params.row.newValue}>
            <p>
              {params.row.newValue}
            </p>
          </Tooltip>
        }
        else {
          return <Tooltip title={params.row.newValue}>
            <p>
              {params.row.newValue}
            </p>
          </Tooltip>

        }
      }
    },
    {
      field: 'dateTime', headerName: 'Date-Time', minWidth: '150',
      renderCell: params => {
        return <Tooltip title={
          new Date(params.row.dateTime).toLocaleString('en-US', {
            timeZone: 'Asia/Singapore',
          })
        }
        >
          <p>
            {
              new Date(params.row.dateTime).toLocaleString('en-US', { timeZone: 'Asia/Singapore' })
            }
          </p>
        </Tooltip>
      }
    },
    {
      field: 'isSuccess', headerName: 'Status',
      renderCell: (params) => {
        if (params.row.isSuccess === 1) {
          return 'Success'
        } else {
          return 'Fail'
        }
      }
    },
  ];
  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
      }}
    >
      <Container maxWidth="xl">
        <CssBaseline />

        <div className="flex" style={{ marginTop: '3rem', marginLeft: '1rem' }}>
          <Typography component="h1" variant="h5">
            Audit Trail
          </Typography>
        </div>

      </Container>

      <Container maxWidth="xl" sx={{ mt: '-3.6rem', mb: 4, }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              {/* <DataTable rows={trails} columns={columns} pageSize={10} rowsPerPageOptions={[15]} rowHeight={45}
                  disableSelectionOnClick={false} /> */}
              <div className="header-right-aligned">
                <FormControl sx={{ width: '120px' }}>
                  <InputLabel id="simple-select-label">Select Year</InputLabel>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={selectedYear}
                    label="Select Month"
                    onChange={(e) => { setSelectedYear(e.target.value) }}
                  >
                    <MenuItem value='2022' selected>2022</MenuItem>
                    <MenuItem value='2023' selected>2023</MenuItem>
                    {/* <MenuItem value='2021'>2021</MenuItem> */}
                  </Select>
                </FormControl>

                <FormControl sx={{ width: '150px' }}>
                  <InputLabel id="simple-select-label">Select Month</InputLabel>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={selectedMonth}
                    label="Select Month"
                    onChange={(e) => { setSelectedMonth(e.target.value) }}
                  >
                    <MenuItem value=''>Select All</MenuItem>
                    <MenuItem value={1}>January</MenuItem>
                    <MenuItem value={2}>February</MenuItem>
                    <MenuItem value={3}>March</MenuItem>
                    <MenuItem value={4}>April</MenuItem>
                    <MenuItem value={5}>May</MenuItem>
                    <MenuItem value={6}>June</MenuItem>
                    <MenuItem value={7}>July</MenuItem>
                    <MenuItem value={8}>August</MenuItem>
                    <MenuItem value={9}>September</MenuItem>
                    <MenuItem value={10}>October</MenuItem>
                    <MenuItem value={11}>November</MenuItem>
                    <MenuItem value={12}>December</MenuItem>
                  </Select>
                </FormControl>

                <Tooltip
                  title="Export all Audit Trails of the selected month"
                  placement="top"
                >
                  <button className="btn light-btn btn-pill" disabled={(selectedMonth === '' || trails.length === 0) ? true : false} onClick={() => exportMonthlyAuditTrails()}>Export Monthly Report</button>
                </Tooltip>
                <Tooltip
                  title="Delete all Audit Trails of the selected month"
                  placement="top"
                >
                  <button className="btn danger-btn btn-pill" disabled={(selectedMonth === '' || trails.length === 0)} onClick={() => deleteTrails()}>Delete all in month</button>
                </Tooltip>
              </div>
              <DataGrid
                ref={el => setDataGridInstance(el)}
                loading={loading}
                autoHeight
                rows={trails}
                columns={columns}
                width='100%'
                pageSize={100}
                rowsPerPageOptions={[100]}
                disableColumnMenu={true}
                getRowId={(row) => row?.id}
                disableSelectionOnClick={false}
                getRowClassName={(params) => {
                  return params.row.isSuccess === 0 ? 'failed' : "";
                }}
                getRowHeight={() => 'auto'}
                onPageChange={() => { window.scrollTo(0, 0) }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box >
  )
}

export default Audits