import React from 'react'
import logo from '../images/warnes-black.png'

const HandoverForm = ({ details, innerRef }) => {
    const data = details
    return (
        <div ref={innerRef} className='handover-form-container' id='handover-form'>
            <div className='right'>
                <img src={logo} alt='logo' />
            </div>
            <h2>DEALER USED CAR HANDOVER FORM</h2>
            <h3>VEHICLE DETAILS</h3>
            <div className='info-grp'>
                <div className='info'>
                    <span className='info-title'>Vehicle Registration No: </span>
                    <span className='info-data'>{data?.car_reg}</span>
                </div>
                <div className='info'>
                    <span className='info-title'>Milage: </span>
                    <span className='info-data'>{data?.mileage}</span>
                </div>
            </div>

            <div className='info'>
                <span className='info-title'>Chassis No: </span>
                <span className='info-data'></span>
            </div>

            <div className='info'>
                <span className='info-title'>Engine No: </span>
                <span className='info-data'></span>
            </div>

            <div className='info'>
                <span className='info-title'>Make/Model: </span>
                <span className='info-data'>{data?.model}</span>
            </div>

            <div className='info bullet'>
                <span className='info-title'>Keys: </span>
                <span className='info-data'>{data?.j}</span>
            </div>

            <div className='info bullet'>
                <span className='info-title'>Others: </span>
                <span className='info-data'>{data?.h}</span>
            </div>

            <div className='info'>
                <span className='info-title'>Remarks: </span>
                <span className='info-data'>{data?.remarks}</span>
            </div>

            <div className='info full'>
                <span className='info-title'>Dealer's Name, Signature & Co. Stamp: </span>
                <span className='info-data full'></span>
            </div>

            <div className='info-grp'>
                <div className='info'>
                    <span className='info-title'>Vehicle Handover Date: </span>
                    <span className='info-data'></span>
                </div>
                <div className='info'>
                    <span className='info-title'>Time: </span>
                    <span className='info-data'></span>
                </div>
            </div>

            <div className='info'>
                <span className='info-title'>Handover By: </span>
                <span className='info-data'></span>
            </div>

            <p className='notice'>Important Notice: Please note that upon the vehicle(s) being handed over to the dealer at the above stated date and time (“Handover”), [________________________________] agrees that [he/it] shall assume full responsibility for any accidents, fines and/or traffic offences involving the vehicle(s) and Wearnes Automotive Pte. Ltd. (“Wearnes”) shall not be liable in any way as to such accidents, fines and/or traffic offences. [___________________________________] shall indemnify and hold Wearnes harmless from any demand, claim and/or proceedings arising out of or in relation to or in connection with any accidents, fines and/or traffic offences involving the vehicle(s) after the Handover.</p>

            <div className='bank-details'>
                <p className='payment-modes'>Payment Modes: </p>
                <h4>By FAST/Pay Now</h4>
                <p>Bank Details: UOB Bank</p>
                <p>Bank Account: 450-302-137-9</p>
                <p>Do include Car No & Company name</p>
            </div>
        </div>
    )
}

export default HandoverForm